<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

// var colors = Highcharts.getOptions().colors.slice(0),
//   dark = -0.5;

// colors[1] = Highcharts.Color(colors[0]).brighten(dark).get();

// colors[3] = Highcharts.Color(colors[2]).brighten(dark).get();

const colors = ["#dddddd", "#178AF4", "#bbbbbb"];
// ["#0178af", "#5d95c1", "#8eb2d2", "#bfd2e5", "#e5ecf5"];
export default {
  props: {
    title: {
      type: String,
      dafault: "",
    },
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
    series: {
      type: Array,
      default() {
        return [];
      },
    },
    yAxis: {
      type: String,
      default: "",
    },
    xAxis: {
      type: String,
      default: "",
    },
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "column",
          backgroundColor: "transparent",
          // height: (9 / 16) * 100 + "%",
        },
        colors: colors,
        credits: {
          enabled: false,
        },
        title: {
          text: this.title,
        },
        // subtitle: {
        //   text: "Source: ",
        // },
        xAxis: {
          categories: this.categories,
          // title: {
          //   text: "stuff",
          // },
          // labels: {
          //   align: "left",
          //   reserveSpace: true,
          // },
        },
        yAxis: {
          min: 0,
          title: {
            text: this.yAxis,
          },
          stackLabels: {
            enabled: false,
            style: {
              fontWeight: "bold",
              color:
                // theme
                (Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                "gray",
            },
          },
        },

        plotOptions: {
          series: {
            enableMouseTracking: false,
            events: {
              legendItemClick: function () {
                return false;
              },
            },
          },

          column: {
            stacking: "percent",
            dataLabels: {
              enabled: false,
            },
          },
        },
        // plotOptions: {
        //   column: {
        //     pointPadding: 0.2,
        //     borderWidth: 0,
        //   },
        // },
        series: this.modSeries,
        responsive: {
          rules: [
            {
              condition: {
                minWidth: 500,
              },
              chartOptions: {
                tooltip: {
                  headerFormat: "<b>{point.x}</b><br/>",
                  pointFormat:
                    "{series.name}: {point.y}%<br/>Total: {point.stackTotal}",
                },
                plotOptions: {
                  column: {
                    dataLabels: {
                      enabled: true,
                      format: "{y} %",
                      style: {
                        textOutline: "none",
                        textShadow: "#999 1px 0 10px",
                      },
                    },
                  },
                },
              },
            },
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                yAxis: {
                  title: {
                    text: null,
                  },
                },

                plotOptions: {
                  column: {
                    stacking: "percent",
                    dataLabels: {
                      enabled: true,
                      format: "{y} %",
                      style: {
                        textOutline: "none",
                        textShadow: "#999 1px 0 10px",
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      };
    },
    modSeries() {
      let modSeries = this.series;

      const dataLabels = {
        enabled: false,
      };

      modSeries.forEach((s) => {
        if (!s.primary) {
          s["dataLabels"] = dataLabels;
        }
      });
      return modSeries;
    },
  },
};
</script>

<style lang="scss">
.highcharts-legend {
  pointer-events: none;
}
</style>
